import Router from "./routers";
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { useEffectOnce } from "react-use";
import { useEffect, useState } from "react";
import './Tailwind.css';
const App = () => {
  useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const param = parts[parts.length - 1];
    const redirectUrl = `https://viettelmanufacturing.vn/check/${param}`;
    window.location.replace(redirectUrl);
  }, []);

  return (
    <div>Redirecting to Check QR code...</div>
  );
}

export default App;
